<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Makbuz Numarası Değiştirme</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="9">
            <rs-text-field
              label="Tahsilat Makbuz No"
              v-model="formData.collecting_number"
            />
          </v-col>

          <v-col sm="3">
            <v-switch label="Makbuz Yazdır" v-model="formData.print_receipt" />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "CollectingReceiptNoForm",
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["incomeExpenseTypeList"]),
  },
  data() {
    return {
      formData: {
        collecting_number: null,
        print_receipt: false,
      },
    };
  },
  mounted() {
    this.formData = {
      collecting_number: null,
      print_receipt: false,
    };
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();

      this.formData = {
        collecting_number: this.selected[0].collecting_number,
        print_receipt: this.selected[0].print_receipt,
      };
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      this.$api
        .put(`income/collectings/${this.selected[0].collecting_id}`, formData)
        .then(() => {
          this.$toast.success("Makbuz no değiştirildi.");
          this.$emit("saved", this.formData);
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
