var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Tedarikçi Avansı Oluştur")]),_c('template',{slot:"body"},[(_vm.bankActivity)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"İşlem Tarihi","value":new Date(_vm.bankActivity.activity_on).toLocaleDateString(),"readonly":"","filled":""}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"label":"İşlem Kalan Tutar","value":_vm.remainingAmount,"disabled":true}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('rs-bank-keywords',{attrs:{"value":_vm.provider.bank_keywords,"readonly":"","filled":""}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-select-provider',{attrs:{"rules":[_vm.rules.required],"readonly":_vm.disabled,"filled":_vm.disabled,"cluster-id":_vm.bankActivity.cluster_id,"required":""},on:{"change:item":_vm.handleProviderChange},model:{value:(_vm.formData.provider_id),callback:function ($$v) {_vm.$set(_vm.formData, "provider_id", $$v)},expression:"formData.provider_id"}})],1),(!_vm.formData.new_invoice)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"rules":[
              _vm.rules.required,
              _vm.rules.min(_vm.formData.amount, 0.01),
              _vm.rules.max(_vm.formData.amount, _vm.remainingAmount),
            ]},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}})],1):_vm._e(),_c('v-col',{attrs:{"sm":"12"}},[_c('rs-bank-keywords',{attrs:{"readonly":_vm.disabled,"filled":_vm.disabled},model:{value:(_vm.formData.additional_bank_keywords),callback:function ($$v) {_vm.$set(_vm.formData, "additional_bank_keywords", $$v)},expression:"formData.additional_bank_keywords"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('rs-textarea',{attrs:{"label":"Açıklama","readonly":_vm.disabled,"filled":_vm.disabled,"rules":[
              _vm.rules.required,
              _vm.rules.maxLength(_vm.formData.description, 1000),
            ],"required":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":""},on:{"cancel":function($event){return _vm.hide()},"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}})],1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }