<template>
  <v-container>
    <v-simple-table class="mt-5 elevation-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="selectable"></th>
            <th>Açıklama</th>
            <th>Blok</th>
            <th>BB</th>
            <th>Borçlu</th>
            <th>Son Ödeme Tarihi</th>
            <th>Toplam Bakiye</th>
          </tr>
        </thead>
        <tfoot v-if="selectable">
          <tr>
            <th colspan="6">Seçilen toplam borç</th>
            <th>{{ totalSelected.toLocaleString() }}₺</th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="item in listShown" :key="item.debt_id">
            <td v-if="selectable">
              <v-checkbox
                :value="item.debt_id"
                v-model="selectedDebts"
                :multiple="multiple"
                @change="handleSelectChange"
              />
            </td>
            <td>{{ item.description }}</td>
            <td>{{ item.block }}</td>
            <td>{{ item.door_number }}</td>
            <td>{{ item.resident || item.resident_name }}</td>
            <td class="text-end">
              {{ new Date(item.last_payment_on).toLocaleDateString() }}
            </td>
            <td class="text-end">
              {{ numberToLocaleFormat(item.total_balance) }}
            </td>
          </tr>
          <tr>
            <td colspan="5" class="text-end">
              {{ numberToLocaleFormat(total) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import { numberToLocaleFormat } from "@/core/helpers";

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    total() {
      let total = 0;

      for (const index in this.list) {
        total += this.list[index].total_balance * 100;
      }

      return new Number(total / 100);
    },
    totalSelected() {
      return this.list
        .filter((item) => this.selectedDebts.indexOf(item.debt_id) !== -1)
        .reduce((previous, current) => {
          return (previous * 100 + current.total_balance * 100) / 100;
        }, 0.0);
    },
    listShown() {
      if (!this.list || this.list.constructor.name !== "Array") {
        return [];
      }

      const list = [].concat(this.list);

      list.sort((a, b) => {
        return a.last_payment_on > b.last_payment_on ? 1 : -1;
      });

      return list;
    },
  },
  data() {
    return {
      selectedDebt: null,
      selectedDebts: [],
    };
  },
  methods: {
    handleSelectChange(item) {
      this.$emit("change", item);
    },
    numberToLocaleFormat,
  },
};
</script>
