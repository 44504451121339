<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Fatura Eşleştir</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="bankActivity">
        <v-row dense>
          <v-col sm="6">
            <rs-text-field
              label="İşlem Tarihi"
              :value="new Date(bankActivity.activity_on).toLocaleDateString()"
              readonly
              filled
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              label="İşlem Kalan Tutar"
              :value="remainingAmount"
              :disabled="true"
            />
          </v-col>

          <v-col sm="12">
            <rs-bank-keywords :value="provider.bank_keywords" readonly filled />
          </v-col>

          <v-col sm="6">
            <rs-select-provider
              v-model="formData.provider_id"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              :cluster-id="bankActivity.cluster_id"
              required
              @change:item="handleProviderChange"
            />
          </v-col>

          <v-col sm="6" v-if="!formData.new_invoice">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, remainingAmount),
              ]"
            />
          </v-col>

          <v-col sm="12">
            <rs-bank-keywords
              v-model="formData.additional_bank_keywords"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              :rules="[
                rules.required,
                rules.maxLength(formData.description, 1000),
              ]"
              required
            />
          </v-col>

          <v-col cols="12">
            <v-row dense>
              <v-col v-if="bankActivity && bankActivity.identity_number">
                <rs-text-field
                  label="TC Kimlik No"
                  :value="bankActivity.identity_number"
                  readonly
                  filled
                />
              </v-col>
              <v-col v-if="bankActivity && bankActivity.tax_number">
                <rs-text-field
                  label="Vergi Kimlik No"
                  :value="bankActivity.tax_number"
                  readonly
                  filled
                />
              </v-col>
              <v-col v-if="bankActivity && bankActivity.iban">
                <rs-text-field
                  label="IBAN"
                  :value="bankActivity.iban"
                  readonly
                  filled
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col sm="12">
            <v-switch
              v-model="formData.new_invoice"
              label="Yeni Fatura Ekle"
              :readonly="disabled"
              :filled="disabled"
              v-if="formData.provider_id"
            />
          </v-col>
        </v-row>

        <PaymentList
          :provider-id="formData.provider_id"
          v-if="formData.provider_id && !formData.new_invoice"
          :selected.sync="selectedInvoices"
          multiple
        >
          <template v-slot:head-text>
            <p class="d-flex mb-1">
              <template v-if="excessAmount">
                <strong class="text-danger">
                  Avans olarak kaydedilecek tutar:&nbsp;
                </strong>
                <rs-table-cell-number price :value="excessAmount" />
              </template>
              &nbsp;
            </p>
          </template>
        </PaymentList>

        <v-row cols="12" dense v-if="formData.new_invoice">
          <v-col sm="6">
            <rs-select
              v-model="invoiceFormData.invoiced_type_id"
              :rules="[rules.required]"
              :label="$t('labels.invoiced_type')"
              :items="invoiceTypeList"
              required
              @change="handleInvoicedTypeIdChange"
            />
          </v-col>

          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.bill_date')"
              v-model="invoiceFormData.billed_on"
              required
              :rules="
                disabled || invoiceFormData.invoiced_type_id === 5
                  ? []
                  : [rules.required]
              "
              :disabled="disabled || invoiceFormData.invoiced_type_id === 5"
            />
          </v-col>

          <v-col sm="6">
            <rs-select-expense-type
              v-model="invoiceFormData.expense_type_id"
              :rules="[rules.required]"
              :label="$t('labels.expense_type')"
              required
            />
          </v-col>

          <v-col sm="6">
            <rs-select
              :label="$t('labels.expense_kind')"
              v-model="invoiceFormData.child_expense_type_id"
              :items="childExpenseTypeList"
              :disabled="!invoiceFormData.expense_type_id"
            />
          </v-col>

          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, remainingAmount),
              ]"
            />
          </v-col>

          <v-col sm="6">
            <rs-text-field
              :affix="invoiceFormData.invoiced_type_id"
              label="Belge No"
              v-model="invoiceFormData.invoice_no"
              :rules="[
                invoiceFormData.invoiced_type_id === 2 ? rules.required : true,
                rules.maxLength(invoiceFormData.invoice_no, 50),
              ]"
              maxlength="50"
              :filled="disabled"
              :readonly="disabled"
            />
          </v-col>

          <v-col sm="12">
            <rs-select-project
              :label="$t('labels.project_code')"
              v-model="invoiceFormData.project_id"
            />
          </v-col>

          <v-col cols="12">
            <rs-textarea
              :label="$t('labels.goods_description')"
              v-model="invoiceFormData.items_description"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-submit
          @submit.prevent="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import hasForm from "@/view/mixins/hasForm";
import PaymentList from "@/view/pages/definitions/cluster-providers/PaymentList";

export default {
  name: "TransactionToInvoice",
  mixins: [hasForm],
  components: {
    PaymentList,
  },
  computed: {
    ...mapGetters(["cluster", "delayCalculationTypeList", "invoiceTypeList"]),
    remainingAmount() {
      if (!this.bankActivity) {
        return 0;
      }

      const amount =
        -1 * this.bankActivity.amount * 100 -
        this.bankActivity.transaction_amount * 100;

      return amount / 100;
    },
    childExpenseTypeList() {
      if (!this.invoiceFormData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.invoiceFormData.expense_type_id
      );
    },
    excessAmount() {
      if (this.selectedInvoices.length === 0) {
        return null;
      }

      let remainingAmount = 100 * this.remainingAmount;

      for (const i in this.selectedInvoices) {
        remainingAmount -= this.selectedInvoices[i].amount_remaining * 100;
      }

      return remainingAmount / 100;
    },
  },
  data() {
    return {
      bankActivity: null,
      selectedInvoices: [],
      formData: {
        new_invoice: true,
      },
      invoiceFormData: {
        invoiced_type_id: 1,
        expense_type_id: null,
        child_expense_type_id: null,
        billed_on: null,
        project_id: null,
        items_description: null,
        invoice_no: null,
      },
      provider: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(bankActivity) {
      this.selectedInvoices = [];
      this.bankActivity = bankActivity;
      this.updateClusterId(bankActivity.cluster_id);

      this.provider = {};

      this.formData = {
        provider_id: null,
        additional_bank_keywords: [],
        amount: this.remainingAmount,
        invoice_ids: [],
        new_invoice: false,
        description: bankActivity.description,
      };

      const activityOn = new Date(bankActivity.activity_on);

      this.invoiceFormData = {
        invoiced_type_id: 1,
        expense_type_id: null,
        child_expense_type_id: null,
        amount: this.remainingAmount,
        billed_on:
          activityOn.getFullYear() +
          "-" +
          (activityOn.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          activityOn.getDate().toString().padStart(2, "0"),
        project_id: null,
        items_description: bankActivity.description,
        invoice_no: null,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.bankActivity = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (!this.formData.new_invoice && this.selectedInvoices.length === 0) {
        this.$toast.error("En az bir ödeme seçmelisiniz.");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      if (formData.new_invoice) {
        formData.invoice_data = Object.assign({}, this.invoiceFormData);
        delete formData.invoice_ids;
      } else {
        formData.invoice_ids = this.selectedInvoices.map((item) => item.id);
      }

      delete formData.new_invoice;

      this.$api
        .post(
          `income/bank-activities/${this.bankActivity.id}/invoice`,
          formData
        )
        .then(() => {
          this.$toast.success("Kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleInvoicedTypeIdChange(newValue) {
      if (newValue === 5 && this.cluster.legal_beginning_date) {
        const split = this.cluster.legal_beginning_date.split("-");
        const beginningDate = new Date(this.cluster.legal_beginning_date)
          ? new Date(Date.UTC(split[0], parseInt(split[1]) - 1, split[2]))
          : null;

        if (!beginningDate) {
          return null;
        }
        this.invoiceFormData.billed_on = new Date(
          Date.UTC(
            beginningDate.getUTCFullYear(),
            beginningDate.getUTCMonth(),
            beginningDate.getUTCDate() - 1
          )
        )
          .toJSON()
          .split("T")[0];
      }
    },
    handleProviderChange(provider) {
      if (provider) {
        this.invoiceFormData.expense_type_id = provider.expense_type_id;
        this.invoiceFormData.child_expense_type_id =
          provider.child_expense_type_id || null;
        this.provider = provider;
      } else {
        this.provider = {};
      }
    },
    handleNewBillChange() {
      const bankActivity = Object.assign({}, this.bankActivity);
      this.bankActivity = null;
      setTimeout(() => (this.bankActivity = bankActivity), 100);
    },
  },
};
</script>
