<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">İcra Başlat</template>
    <template slot="body">
      <p>{{ selected.length }} adet borç için icra başlatıyorsunuz.</p>

      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="4">
            <rs-datepicker
              label="İcraya Veriliş Tarihi"
              v-model="formData.issued_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <!-- <v-col cols="4">
            <rs-select
              label="İcraya Verilen"
              :items="enforcementPersonType"
              v-model="formData.enforcement_person_type_id"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col> -->
          <v-col cols="4">
            <rs-select-provider
              label="Avukat"
              :search-params="{ provider_type: 1 }"
              :form-params="{ provider_type_id: 1 }"
              v-model="formData.lawyer_id"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="4">
            <rs-select
              v-if="companyId"
              label="İcraya Veren"
              :items="companyUserList"
              item-value="company_user_id"
              v-model="formData.company_user_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>

      <DebtList :list="selected" />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import DebtList from "./DebtList";

export default {
  components: {
    DebtList,
  },
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: false,
      default: null,
    },
    resident: {
      type: Object,
      required: false,
      default: null,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "companyId", "lawyers"]),
  },
  data() {
    return {
      showBothOption: false,
      formData: {},
      enforcementPersonTypeDefault: [
        {
          id: 1,
          name: "Bağımsız Bölüm Sahibi",
        },
        {
          id: 2,
          name: "Borçlu",
        },
        // {
        //   id: 3,
        //   name: "Her İkisi",
        // },
      ],
      enforcementPersonType: [],
      companyUserList: [],
    };
  },
  watch: {
    companyId(newValue) {
      if (newValue) {
        this.loadCompanyUserList();
      } else {
        this.companyUserList = [];
      }
    },
  },
  methods: {
    show() {
      // if (!this.companyId) {
      //   this.$toast.error("Bir yönetim şirketi seçmelisiniz.");
      //   return;
      // }

      if (this.companyId) {
        this.loadCompanyUserList();
      }

      this.formData = {
        issued_on: new Date().toJSON().split("T")[0],
        // enforcement_person_type_id: null,
        lawyer_id: null,
        company_user_id: null,
      };

      let showBothOption = true;

      this.enforcementPersonType = [].concat(this.enforcementPersonTypeDefault);
      // this.enforcementPersonType.pop();

      if (this.house) {
        for (const index in this.house.house_users) {
          if (this.house.house_users[index].house_user_type_id === 2) {
            for (const index2 in this.selected) {
              if (
                this.selected[index2].resident_id !==
                this.house.house_users[index].resident_id
              ) {
                showBothOption = false;
              }
            }
          }
        }

        if (showBothOption) {
          this.enforcementPersonType = [].concat(
            this.enforcementPersonTypeDefault
          );
        }
      }

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    loadCompanyUserList() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const params = {
        company_id: this.companyId,
      };

      this.$api
        .query("company-users", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.companyUserList = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = {
        debt_ids: this.selected.map((item) => item.debt_id),
        resident_id: this.selected[0].resident_id || this.resident.id,
        cluster_id: this.clusterId,
        lawyer_id: this.formData.lawyer_id,
        issued_on: this.formData.issued_on,
        company_user_id: this.formData.company_user_id,
      };

      this.$api
        .post("enforcements", formData)
        .then((response) => {
          this.$toast.success("İcra başlatıldı.");
          this.$emit("saved");
          this.hide();
          this.$router.push({
            name: "enforcements.edit",
            params: { id: response.data.data.id },
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
