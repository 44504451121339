<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Borç Paylaştır</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense align="end">
          <v-col sm="6">
            <rs-select-resident
              v-if="house"
              label="Paylaşılan Sakin"
              v-model="formData.resident_id"
              :rules="[rules.required, residentIdNotSame]"
              :cluster-id="clusterId"
              :house-id="onlyHouseUsers ? house.id : null"
              required
            />
          </v-col>
          <v-col sm="6" v-if="byAmount">
            <rs-money-field
              label="Paylaşılan Tutar"
              v-model="formData.amount"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, totalAmount),
                      notEqualToAssessment(),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="3" v-if="!byAmount">
            <rs-text-field
              label="Paylaşılan Gün"
              v-model="days"
              :rules="
                disabled
                  ? []
                  : [rules.required, rules.min(days, 1), rules.max(days, 30)]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col v-if="!byAmount">
            <rs-action @click="handleAutoShareClick" :is-loading="isLoading"
              >Otomatik</rs-action
            >
          </v-col>
          <v-col sm="6">
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              @change="handleOnlyHouseUsersChange"
            ></v-switch>
          </v-col>
          <v-col>
            <v-switch
              v-model="byAmount"
              :disabled="disabled"
              :label="byAmount ? 'Tutar girerek' : 'Gün girerek'"
              hide-details="auto"
            ></v-switch>
          </v-col>
          <!-- <v-col sm="6">
            <rs-select-house
              v-if="formData.resident_id && !onlyHouseUsers"
              label="Paylaşılan Bağımsız Bölüm"
              v-model="formData.house_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :resident-id="formData.resident_id"
              required
            />
          </v-col> -->
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              rows="2"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <DebtList :list="selected" />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";
import DebtList from "./DebtList";

export default {
  name: "DebtShareForm",
  mixins: [hasForm],
  components: {
    DebtList,
  },
  props: {
    house: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
    residentIdNotSame() {
      if (!this.selected.length || !this.formData.resident_id) {
        return false;
      }

      return (
        this.formData.resident_id !== this.selected[0].resident_id ||
        "Borçlu kişiye aynı borcu paylaştıramazsınız."
      );
    },
  },
  data() {
    return {
      formData: {},
      onlyHouseUsers: true,
      totalAmount: null,
      byAmount: true,
      days: 1,
    };
  },
  methods: {
    show() {
      this.byAmount = true;
      this.$refs.sidePanel.show();

      this.formData = {
        resident_id: null,
        amount: null,
        description: this.selected[0].description,
      };

      this.totalAmount = (this.selected[0].balance * 100 - 100) / 100;
      this.formData.amount = this.totalAmount;
      this.onlyHouseUsers = true;
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (!this.formData.house_id) {
        this.formData.house_id = this.house.id;
      }

      const params = this.formData;

      if (!this.byAmount) {
        this.formData.amount =
          (this.selected[0].balance * 100 * (this.days / 30)) / 100;
      }

      this.isLoading = true;

      this.$api
        .post(`income/debts/${this.selected[0].debt_id}/share`, params)
        .then(() => {
          this.$toast.success("Seçilen borç paylaştırıldı.");
          this.$emit("saved", this.formData);
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleOnlyHouseUsersChange() {
      this.formData.resident_id = null;
      this.formData.house_id = null;
    },
    notEqualToAssessment() {
      if (!this.selected.length) {
        return false;
      }

      if (this.selected[0].assessment === this.formData.amount) {
        return "Borcun tamamını paylaştıramazsınız. Bu işlem için borçlu/ödeyen değiştir tuşunu kullanabilirsiniz.";
      }

      return true;
    },
    handleAutoShareClick() {
      if (!this.formData.resident_id) {
        this.$toast.warning("Otomatik gün sayısı için sakin seçmelisiniz.");
      }

      const params = {
        resident_id: this.formData.resident_id,
        house_id: this.house.id,
      };

      this.isLoading = true;

      this.$api
        .query(`clusters/${this.clusterId}/house-users`, { params })
        .then((response) => {
          if (!response.data.data.length) {
            this.$toast.error(
              "Paylaşılacak kişinin bağımsız bölümle ilişkisi bulunamadı"
            );
            return false;
          }

          const debt = this.selected[0];
          const newHouseUser = response.data.data[0];
          const enteredOn = new Date(newHouseUser.entered_on);
          const issuesOn = new Date(debt.issues_on);
          const days = Math.round(
            (enteredOn - issuesOn) / (1000 * 60 * 60 * 24)
          );

          if (days <= 0 || days >= 31) {
            this.$toast.error(
              "Borcun tahakkuk tarihi ile seçilen kişinin bağımsız bölüme girişi arasında 31 günden fazla süre var."
            );
          }

          this.days = days;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
