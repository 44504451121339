<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bağımsız Bölüm Fiş - Borçlandırma</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.date')"
              v-model="formData.issues_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled || formData.is_transferred"
              required
              @change="autoUpdateDescription"
            />
          </v-col>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.last_payment_date')"
              v-model="formData.last_payment_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <rs-select
              label="Borçlandırma Tipi"
              :items="incomeTypeList"
              v-model="formData.income_type_id"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              @change="handleIncomeTypeChange"
            />
          </v-col>
          <v-col cols="6">
            <rs-select
              v-if="formData.income_type_id === 10"
              :items="customIncomeTypeList.filter((item) => item.is_active)"
              :label="$t('labels.custom_expense_type')"
              :rules="[rules.required]"
              v-model="formData.child_income_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
              @change="autoUpdateDescription"
            />
          </v-col>
          <v-col sm="6">
            <v-switch
              label="Gecikme Borcu"
              v-model="formData.is_deferment"
              :readonly="disabled || formData.income_type_id === 14"
              :filled="disabled || formData.income_type_id === 14"
            />
          </v-col>
          <v-col sm="6">
            <v-switch
              label="Devir Fişi"
              v-model="formData.is_transferred"
              :readonly="
                disabled || [13, 14, 15].indexOf(formData.income_type_id) !== -1
              "
              :filled="
                disabled || [13, 14, 15].indexOf(formData.income_type_id) !== -1
              "
            />
          </v-col>
          <v-col cols="6">
            <rs-select-resident
              v-if="house"
              label="Borçlu"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="cluster.id"
              :house-id="onlyHouseUsers ? house.id : null"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              :readonly="disabled"
              :filled="disabled"
              @change="handleOnlyHouseUsersChange"
            ></v-switch>
          </v-col>
          <!-- <v-col sm="6">
            <rs-select-house
              v-if="formData.resident_id && !onlyHouseUsers"
              label="Bağımsız Bölüm"
              v-model="formData.house_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :resident-id="formData.resident_id"
              required
            />
          </v-col> -->
          <v-col cols="6">
            <rs-money-field
              v-model="formData.amount"
              label="Tahakkuk Eden Tutar"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, 9999999.99),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <rs-select-project
              v-model="formData.project_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <rs-select
              :label="$t('labels.delay_calculation_type')"
              :rules="[rules.required]"
              :items="[
                { id: 'daily', name: 'Günlük' },
                { id: 'monthly', name: 'Aylık' },
              ]"
              v-model="formData.deferment_calculation_period"
              :readonly="disabled || defermentDisabled"
              :filled="disabled || defermentDisabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              :label="$t('labels.delay_percentage')"
              v-model="formData.deferment_interest_percent"
              :readonly="disabled || defermentDisabled"
              :filled="disabled || defermentDisabled"
              prefix="%"
              :rules="
                formData.deferment_calculation_period ? [rules.required] : []
              "
              :required="formData.deferment_calculation_period"
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              :label="$t('labels.delay_option_days')"
              v-model="formData.deferment_grace_period"
              :readonly="disabled || defermentDisabled"
              :filled="disabled || defermentDisabled"
              suffix="Gün"
            />
          </v-col>
          <v-col sm="6">
            <v-switch
              label="Tahakkuk Mobil Bildirimi Gönder"
              v-model="formData.send_push_notification"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>

      <v-row dense cols="12" v-if="originalData.id">
        <rs-confirm
          ref="confirmDelete"
          :loading="isLoading"
          @confirmed="handleDeleteSubmitClick"
          @cancelled="$refs.confirmDelete.hide()"
        />

        <v-col cols="12">
          <h3>Tahsilatlar</h3>
        </v-col>

        <v-col cols="12">
          <v-btn
            v-if="
              originalData.debt_collectings.length &&
              !isActivity &&
              can('edit-house-income')
            "
            class="float-end"
            color="pink"
            outlined
            small
            @click="handleDeleteClick"
            :disabled="selectedItems.length === 0"
            :loading="isLoading"
          >
            Borç Kapamayı İptal Et
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="originalData.debt_collectings.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th>Ödeme Tarihi</th>
                  <th>Kapanan Tutar</th>
                  <th>Kapanan Ana Para</th>
                  <th>Kapanan Gecikme</th>
                  <th>Fark Gün</th>
                  <th>Açıklama</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in originalData.debt_collectings"
                  :key="item.id"
                >
                  <td>
                    <v-checkbox
                      v-model="selectedItems"
                      label=""
                      :value="item"
                    />
                  </td>
                  <td>
                    <rs-table-cell-date :value="item.collecting.collected_on" />
                  </td>
                  <td>
                    <rs-table-cell-number
                      price
                      :value="item.amount + item.deferment_amount"
                    />
                  </td>
                  <td>
                    <rs-table-cell-number price :value="item.amount" />
                  </td>
                  <td>
                    <rs-table-cell-number
                      price
                      :value="item.deferment_amount"
                    />
                  </td>
                  <td>
                    {{ getDayDiff(item) }}
                  </td>
                  <td>{{ item.collecting.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" v-else>
          <p>Bu tahsilatla hiç borç kapatılmadı.</p>
        </v-col>
      </v-row>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "DebtForm",
  mixins: [hasForm, hasPermissions],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "cluster",
      "clusterId",
      "customIncomeTypeList",
      "delayCalculationTypeList",
      "incomeTypeList",
      "monthList",
    ]),
  },
  watch: {
    "formData.is_transferred"(newValue) {
      if (!this.disabled && newValue) {
        if (!this.cluster || !this.cluster.legal_beginning_date) {
          this.formData.issues_on = null;
          return;
        }

        const split = this.cluster.legal_beginning_date.split("-");

        const date = this.cluster.legal_beginning_date
          ? new Date(Date.UTC(split[0], parseInt(split[1]) - 1, split[2]))
          : null;

        if (!date) {
          return null;
        }

        this.formData.issues_on = new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() - 1
          )
        )
          .toJSON()
          .split("T")[0];
      }
    },
  },
  data() {
    return {
      onlyHouseUsers: true,
      formData: {},
      id: null,
      disabled: false,
      extraParams: {},
      defermentDisabled: false,
      selectedItems: [],
    };
  },
  methods: {
    show(id, disabled, extraParams) {
      this.formData = {
        resident_id: null,
        income_type_id: null,
        child_income_type_id: null,
        issues_on: null,
        amount: null,
        project_id: null,
        send_push_notification: true,
        description: null,
        is_deferment: false,
        is_transferred: false,
        last_payment_on: null,
        deferment_calculation_period: null,
        deferment_interest_percent: null,
        deferment_grace_period: null,
        house_id: null,
      };
      this.originalData = {};
      this.stopDeferment = false;
      this.extraParams = {};
      this.selectedItems = [];

      if (id) {
        this.id = id;
        this.load();
      } else {
        this.formData.deferment_calculation_period =
          this.cluster.deferment_calculation_period;
        this.formData.deferment_grace_period =
          this.cluster.deferment_grace_period;
        this.formData.deferment_interest_percent =
          this.cluster.deferment_interest_percent;
      }

      if (disabled) {
        this.disabled = true;
      }

      if (extraParams) {
        this.extraParams = extraParams;
      }

      this.$refs.sidePanel.show();
    },
    load() {
      if (this.isLoading || !this.id) return false;

      this.isLoading = true;
      this.selectedItems = [];

      this.$api
        .query(`income/debts/${this.id}`)
        .then((response) => {
          this.loadData(response);

          this.formData.issues_on =
            this.originalData.house_assessment.issues_on;
          this.formData.income_type_id =
            this.originalData.house_assessment.income_type_id;
          this.formData.child_income_type_id =
            this.originalData.house_assessment.child_income_type_id;
          this.formData.is_deferment =
            this.originalData.house_assessment.is_deferment;
          this.formData.is_transferred =
            this.originalData.house_assessment.is_transferred;
          this.formData.project_id =
            this.originalData.house_assessment.project_id;
          this.formData.description =
            this.originalData.house_assessment.description;
          this.formData.house_id = this.originalData.house_assessment.house_id;
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
          this.selectedItems = [];
        });
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$toast.error("Borç güncelleme yapamazsınız.");
        return false;
      } else {
        for (const index in formData) {
          if (formData[index] === null) {
            delete formData[index];
          }
        }
      }

      this.isLoading = true;

      const params = Object.assign({}, this.extraParams, formData);

      this.$api
        .post("income/debts", params)
        .then(() => {
          this.$toast.success("Borç kaydedildi");
          this.$emit("saved", params);
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleOnlyHouseUsersChange() {
      this.formData.resident_id = null;
      this.formData.house_id = null;
    },
    handleIncomeTypeChange(value) {
      this.formData.child_income_type_id = null;
      this.defermentDisabled = false;

      if (value === 14) {
        this.formData.is_deferment = true;
        this.formData.is_transferred = true;
        this.formData.deferment_interest_percent = 0;
        this.formData.deferment_grace_period = 0;
        this.defermentDisabled = true;
      } else if (value === 13) {
        this.formData.is_transferred = true;
        this.formData.is_deferment = false;
      } else if (value === 15) {
        this.formData.is_transferred = true;
        this.formData.is_deferment = false;
        this.formData.deferment_interest_percent = 0;
        this.formData.deferment_grace_period = 0;
        this.defermentDisabled = true;
      } else {
        this.formData.is_transferred = false;
        this.formData.is_deferment = false;
      }

      this.$nextTick(() => this.autoUpdateDescription());
    },
    autoUpdateDescription() {
      if (!this.formData.issues_on || !this.formData.income_type_id) {
        return;
      }

      let str;
      const date = new Date(this.formData.issues_on);

      if (this.formData.is_transferred) {
        str = date.toLocaleDateString();
      } else {
        str = date.getFullYear();
        str += " ";
        str += this.monthList[date.getMonth()];
      }

      str += " ";

      for (const index in this.incomeTypeList) {
        if (this.incomeTypeList[index].id === this.formData.income_type_id) {
          str += this.incomeTypeList[index].name;
        }
      }

      if (this.formData.child_income_type_id) {
        for (const index in this.customIncomeTypeList) {
          if (
            this.customIncomeTypeList[index].id ===
            this.formData.child_income_type_id
          ) {
            str += " " + this.customIncomeTypeList[index].name;
          }
        }
      }

      this.formData.description = str;
    },
    handleDeleteClick() {
      if (this.selectedItems.length === 0) {
        return;
      }

      this.$refs.confirmDelete.show(
        "Borç kapamayı iptal etmek istediğinizden emin misiniz?"
      );
    },
    handleDeleteSubmitClick() {
      this.$refs.confirmDelete.hide();

      const params = {
        debt_collecting_ids: this.selectedItems.map((item) => item.id),
      };

      this.isLoading = true;

      this.$api
        .post("income/cancel-debt-collectings", params)
        .then(() => {
          setTimeout(() => {
            this.load();
            this.selectedItems = [];
            this.$emit("saved");
          }, 100);
          this.$toast.success("Seçilen borç kapamalar iptal edildi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getDayDiff(item) {
      const dt1 = new Date(this.originalData.house_assessment.last_payment_on);
      const dt2 = new Date(item.collecting.collected_on);

      const diff = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );

      return diff > 0 ? diff : 0;
    },
  },
};
</script>
