var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.bankActivity)?_c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Bankadan Bankaya Transfer")]),_c('template',{slot:"body"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"6"}},[_c('rs-select-bank-account',{attrs:{"label":_vm.bankActivity.amount > 0
                ? 'Transferi Alan Banka Hesabı'
                : 'Transferi Gönderen Banka Hesabı',"value":_vm.bankActivity.bank_account_id,"cluster-id":_vm.bankActivity.cluster_id,"readonly":"","filled":"","required":""}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"Tarih","value":new Date(_vm.bankActivity.activity_on).toLocaleDateString(),"readonly":"","filled":""}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"value":_vm.bankActivity.amount,"disabled":true}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-select-bank-account',{attrs:{"label":_vm.bankActivity.amount > 0
                ? 'Transferi Gönderen Banka Hesabı'
                : 'Transferi Alan Banka Hesabı',"cluster-id":_vm.bankActivity.cluster_id,"rules":[_vm.rules.required],"readonly":_vm.disabled,"required":"","filled":_vm.disabled},model:{value:(_vm.formData.bank_account_id),callback:function ($$v) {_vm.$set(_vm.formData, "bank_account_id", $$v)},expression:"formData.bank_account_id"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('rs-textarea',{attrs:{"label":"Açıklama","readonly":_vm.disabled,"filled":_vm.disabled,"rules":[
              _vm.rules.required,
              _vm.rules.maxLength(_vm.formData.description, 1000),
            ],"required":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":""},on:{"cancel":function($event){return _vm.hide()},"submit":_vm.handleFormSubmit}})],1)],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }