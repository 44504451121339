var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Avans - Borç Eşleştirme")]),_c('template',{slot:"body"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"cols":"12","dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('rs-money-field',{attrs:{"label":"Avanstan Kullanılacak Tutar","rules":_vm.disabled
                ? []
                : [
                    _vm.rules.required,
                    _vm.rules.min(_vm.formData.amount, 0.01),
                    _vm.rules.max(_vm.formData.amount, _vm.maxAmount),
                  ],"disabled":_vm.disabled},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-switch',{attrs:{"label":"Tahsilat Bildirimi Gönder"},model:{value:(_vm.formData.send_push_notification),callback:function ($$v) {_vm.$set(_vm.formData, "send_push_notification", $$v)},expression:"formData.send_push_notification"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-select-resident',{attrs:{"label":"Borçlu Sakin","rules":[_vm.rules.required],"cluster-id":_vm.clusterId,"house-id":_vm.houseId && _vm.onlyHouseUsers ? _vm.houseId : null,"required":""},on:{"change":_vm.handleResidentChange},model:{value:(_vm.formData.resident_id),callback:function ($$v) {_vm.$set(_vm.formData, "resident_id", $$v)},expression:"formData.resident_id"}})],1),_c('v-col',{attrs:{"sm":"6"}},[(_vm.houseId)?_c('v-switch',{attrs:{"label":_vm.$t('labels.show_only_house_users'),"hide-details":"auto"},on:{"change":_vm.handleOnlyHouseUsersChange},model:{value:(_vm.onlyHouseUsers),callback:function ($$v) {_vm.onlyHouseUsers=$$v},expression:"onlyHouseUsers"}}):_vm._e()],1)],1),(!_vm.isLoading)?_c('DebtList',{attrs:{"list":_vm.listShown,"selectable":"","multiple":"","required":""},on:{"change":_vm.handleDebtChange}}):_vm._e(),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":""},on:{"cancel":function($event){return _vm.hide()},"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }