<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Eşleştir</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="bankActivity">
        <v-row dense>
          <v-col sm="6">
            <rs-text-field
              label="İşlem Tarihi"
              :value="new Date(bankActivity.activity_on).toLocaleDateString()"
              readonly
              filled
            />
          </v-col>

          <v-col sm="6">
            <rs-money-field
              label="İşlem Kalan Tutar"
              :value="remainingAmount"
              :disabled="true"
            />
          </v-col>

          <v-col sm="6">
            <rs-select-enforcement
              label="İcra Dosyası"
              v-model="formData.enforcement_id"
              :cluster-id="clusterId"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              @change:option="handleEnforcementChange"
              :extra-params="{ is_closed: false }"
              required
            />
          </v-col>

          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, maxAmount),
              ]"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Tahsilat Açıklaması"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              :rules="[rules.maxLength(formData.description, 1000)]"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-save-close
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  components: {},
  computed: {
    ...mapGetters(["collectingTypeList", "incomeTypeList"]),
    remainingAmount() {
      if (!this.bankActivity) {
        return 0;
      }

      const amount =
        this.bankActivity.amount * 100 -
        this.bankActivity.transaction_amount * 100;

      return amount / 100;
    },
    maxAmount() {
      if (
        this.enforcement &&
        this.enforcement.left_amount < this.remainingAmount
      ) {
        return this.enforcement.left_amount;
      }

      return this.remainingAmount;
    },
  },
  watch: {
    maxAmount() {
      this.formData.amount = this.maxAmount;
    },
  },
  data() {
    return {
      bankActivity: null,
      hasAdvance: false,
      residentId: null,
      enforcement: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(bankActivity) {
      this.residentId = null;
      this.enforcement = {};
      this.bankActivity = bankActivity;
      this.clusterId = bankActivity.cluster_id;

      if (this.bankActivity.resident_id) {
        this.residentId = this.bankActivity.resident_id;
      }

      this.updateClusterId(
        bankActivity.cluster_id || bankActivity.bank_account.cluster_id
      );

      this.formData = {
        enforcement_id: null,
        amount: this.remainingAmount,
        description: bankActivity.description,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.bankActivity = null;
      this.residentId = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      this.$api
        .post(
          `income/bank-activities/${this.bankActivity.id}/enforcement-transaction`,
          formData
        )
        .then(() => {
          this.$toast.success("Kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEnforcementChange(item) {
      this.enforcement = item ? item.item : null;
    },
  },
};
</script>
