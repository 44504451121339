<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tedarikçi Avansı Oluştur</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="bankActivity">
        <v-row dense>
          <v-col sm="6">
            <rs-text-field
              label="İşlem Tarihi"
              :value="new Date(bankActivity.activity_on).toLocaleDateString()"
              readonly
              filled
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              label="İşlem Kalan Tutar"
              :value="remainingAmount"
              :disabled="true"
            />
          </v-col>

          <v-col sm="12">
            <rs-bank-keywords :value="provider.bank_keywords" readonly filled />
          </v-col>

          <v-col sm="6">
            <rs-select-provider
              v-model="formData.provider_id"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              :cluster-id="bankActivity.cluster_id"
              required
              @change:item="handleProviderChange"
            />
          </v-col>

          <v-col sm="6" v-if="!formData.new_invoice">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, remainingAmount),
              ]"
            />
          </v-col>

          <v-col sm="12">
            <rs-bank-keywords
              v-model="formData.additional_bank_keywords"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              :rules="[
                rules.required,
                rules.maxLength(formData.description, 1000),
              ]"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-submit
          @submit.prevent="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "TransactionToAdvanceForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["cluster"]),
    remainingAmount() {
      if (!this.bankActivity) {
        return 0;
      }

      const amount =
        -1 * this.bankActivity.amount * 100 -
        this.bankActivity.transaction_amount * 100;

      return amount / 100;
    },
  },
  data() {
    return {
      bankActivity: null,
      formData: {
        provider_id: null,
        amount: this.remainingAmount,
        additional_bank_keywords: [],
        description: null,
      },
      provider: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(bankActivity) {
      this.selectedInvoices = [];
      this.bankActivity = bankActivity;
      this.updateClusterId(bankActivity.cluster_id);

      this.provider = {};

      this.formData = {
        provider_id: null,
        amount: this.remainingAmount,
        additional_bank_keywords: [],
        description: bankActivity.description,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.bankActivity = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      this.$api
        .post(
          `income/bank-activities/${this.bankActivity.id}/expense-advance`,
          formData
        )
        .then(() => {
          this.$toast.success("Kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
