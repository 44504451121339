<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="providerId"
    v-bind="dataTableAttrs"
    :headers="headers"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @item-selected="handleItemSelected"
    @update:items-per-page="handleItemsPerPageUpdated"
    @toggle-select-all="handleItemSelected"
    :single-select="!multiple"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title="Aktif Ödeme Listesi"
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :edit-enabled="selectedItems.length === 1"
        @click:edit="handleEditClick"
        :search.sync="search.query"
      />

      <p class="mb-1">
        <template v-if="selectedItems.length > 0">
          Seçilen toplam borç: {{ numberToLocaleFormat(totalSelectedAmount) }}₺
        </template>
      </p>

      <slot name="head-text" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals
        :headers="headers"
        :totals="footTotals"
        v-if="list.length > 0"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <router-link
        class="btn btn-icon btn-sm btn-clean"
        :to="{
          name: 'expenses.invoices.edit',
          params: { id: item.id },
        }"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </router-link>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.billed_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.last_payment_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.document_no="{ value }">
      <rs-table-cell-number :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount_remaining="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount_to_pay="{ value }">
      <strong><rs-table-cell-number price :value="value" /></strong>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount_collected="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.has_installments="{ item }">
      {{ item.has_installments ? "Taksitli" : "Tek Ödeme" }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.description="{ item, value }">
      <router-link
        :to="{ name: 'expenses.invoices.edit', params: { id: item.id } }"
        title="Detay Göster"
      >
        <rs-table-cell-string :limit="20" :value="value" />
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import hasDataTable from "@/view/mixins/hasDataTable";

export default {
  mixins: [hasDataTable],
  props: {
    providerId: {
      type: Number,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    providerId: {
      handler() {
        this.loadList();
      },
    },
  },
  computed: {
    ...mapGetters(["bankList"]),
    totalSelectedAmount() {
      let amount = 0;

      for (const index in this.selectedItems) {
        amount += this.selectedItems[index].amount_remaining * 100;
      }

      return amount / 100;
    },
  },
  data() {
    return {
      options: {
        page: 1,
        sortBy: ["last_payment_on"],
        sortDesc: [false],
      },
      headers: [
        {
          text: "Fatura Tarihi",
          value: "billed_on",
          searchable: "date",
        },
        {
          text: "Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "date",
        },
        {
          text: "Belge No",
          value: "invoice_no",
          searchable: "text",
        },
        {
          text: "Ödeme Şekli",
          value: "has_installments",
          searchable: "select",
          options: () => [
            { id: false, name: "Tek Ödeme" },
            { id: true, name: "Taksitli" },
          ],
        },
        {
          text: "Toplam Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "amount_collected",
          searchable: "number",
          align: "end",
        },
        {
          text: "Toplam Kalan Tutar",
          value: "amount_remaining",
          searchable: "number",
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Banka",
          value: "bank_1",
          searchable: "multiselect",
          options: () => this.bankList,
        },
        {
          text: "IBAN",
          value: "bank_1_iban",
          searchable: "text",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.providerId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.provider_id = this.providerId;
      params.amount_remaining = {
        min: 0.01,
      };

      this.$api
        .query("expense/invoices", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleItemSelected() {
      this.$nextTick(() => {
        this.$emit("update:selected", this.selectedItems);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.selectedItems = this.selected;
      this.loadList();
    });
  },
};
</script>
