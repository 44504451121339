<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Paylaştır</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="6">
            <rs-select-resident
              v-if="house"
              label="Paylaşılan Sakin"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :house-id="onlyHouseUsers ? house.id : null"
              @change="formData.house_id = null"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-money-field
              label="Paylaşılan Tutar"
              v-model="formData.amount"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, totalAmount),
                      notEqualToTotal(),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="12" class="text-end">
            <span class="text-danger"
              >Yukarıdaki alana en fazla {{ this.totalAmount }}₺
              girebilirsiniz</span
            >
          </v-col>
          <v-col sm="6">
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              @change="handleOnlyHouseUsersChange"
            ></v-switch>
          </v-col>
          <v-col sm="6">
            <rs-select-house
              v-if="formData.resident_id && !onlyHouseUsers"
              label="Paylaşılan Bağımsız Bölüm"
              v-model="formData.house_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :resident-id="formData.resident_id"
              :extra-params="{ house_user_is_active: true }"
              required
            />
          </v-col>
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              rows="2"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <!-- <DebtList :list="selected" /> -->
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "CollectingShareForm",
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
    // residentIdNotSame() {
    //   if (!this.selected.length || !this.formData.resident_id) {
    //     return false;
    //   }

    //   return (
    //     this.formData.resident_id !== this.selected[0].resident_id ||
    //     "Ödeyen kişiye aynı tahsilatı paylaştıramazsınız."
    //   );
    // },
  },
  data() {
    return {
      formData: {},
      onlyHouseUsers: true,
      totalAmount: null,
    };
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();

      this.formData = {
        resident_id: null,
        house_id: null,
        amount: null,
        description: this.selected[0].description,
      };

      this.totalAmount = -1 * this.selected[0].total_balance;
      this.onlyHouseUsers = true;
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      if (!this.formData.house_id) {
        this.formData.house_id = this.house.id;
      }

      const params = this.formData;

      this.$api
        .post(
          `income/collectings/${this.selected[0].collecting_id}/share`,
          params
        )
        .then(() => {
          this.$toast.success("Seçilen tahsilat paylaştırıldı.");
          this.$emit("saved", this.formData);
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleOnlyHouseUsersChange() {
      this.formData.resident_id = null;
      this.formData.house_id = null;
    },
    notEqualToTotal() {
      if (!this.selected.length) {
        return false;
      }

      if (this.selected[0].payed === this.formData.amount) {
        return "Tahsilatın tamamını paylaştıramazsınız. Bu işlem için borçlu/ödeyen değiştir tuşunu kullanabilirsiniz.";
      }

      return true;
    },
  },
};
</script>
