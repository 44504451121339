<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Türü Değiştirme</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col cols="12">
            <rs-select
              label="Tahsilat Türü"
              :items="collectingTypeList"
              v-model="formData.collecting_type_id"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "CollectingTypeForm",
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["collectingTypeList"]),
  },
  data() {
    return {
      formData: {
        collecting_type_id: null,
      },
    };
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();

      this.formData = {
        collecting_type_id: null,
      };
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      this.$api
        .put(`income/collectings/${this.selected[0].collecting_id}`, formData)
        .then(() => {
          this.$toast.success("Tahsilat türü değiştirildi.");
          this.$emit("saved", this.formData);
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
