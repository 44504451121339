<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Fişi</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col sm="12">
            <v-radio-group
              row
              mandatory
              v-model="formData.usage_type_id"
              @change="handleUsageTypeChange"
            >
              <v-radio
                value="email-resident"
                label="TYA Sakinine E-Posta Gönder"
              />
              <v-radio value="email-any" label="E-Posta Adresine Gönder" />
              <v-radio value="download" label="Yazdır" />
            </v-radio-group>
          </v-col>

          <v-col sm="12">
            <rs-select
              label="Dosya Tipi"
              v-model="formData.file_type"
              :items="fileTypeList"
              :rules="[rules.required]"
              required
            />
          </v-col>

          <v-col sm="6" v-if="formData.usage_type_id === 'email-resident'">
            <rs-select-resident
              v-if="house"
              label="Paylaşılan Kişi"
              v-model="formData.email_resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :house-id="onlyHouseUsers ? house.id : null"
              required
            />
          </v-col>

          <v-col sm="6" v-if="formData.usage_type_id === 'email-resident'">
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              @change="formData.email_resident_id = null"
            ></v-switch>
          </v-col>

          <v-col cols="12" v-if="formData.usage_type_id === 'email-any'">
            <rs-text-field
              type="email"
              :label="$t('labels.email')"
              v-model="formData.email"
              :rules="[rules.required, rules.email]"
              required
            />
          </v-col>

          <v-col cols="6" v-if="formData.usage_type_id === 'download'">
            <rs-select
              label="Sayfa Tipi"
              v-model="formData.paper_size"
              :items="paperSizeList"
              :rules="[rules.required]"
              required
            />
          </v-col>

          <v-col cols="6" v-if="formData.usage_type_id === 'download'">
            <rs-text-field
              label="Kopya Sayısı"
              v-model="formData.copies"
              :rules="[
                rules.required,
                rules.min(formData.copies, 1),
                rules.max(formData.copies, 100),
              ]"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-save-close
          :save-label="
            formData.usage_type_id === 'download' ? 'İndir' : 'Gönder'
          "
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      isLoading: false,
      formData: {},
      receiptData: {},
      onlyHouseUsers: true,
      fileTypeList: [
        {
          id: "odt",
          name: "ODT (LibreOffice)",
        },
        {
          id: "docx",
          name: "DOCX (Microsoft Word)",
        },
        {
          id: "pdf",
          name: "PDF (Adobe PDF)",
        },
        {
          id: "png",
          name: "PNG (Görsel)",
        },
        {
          id: "xlsx",
          name: "XLSX (Microsoft Excel)",
        },
        {
          id: "ods",
          name: "ODS (LibreOffice)",
        },
      ],
      paperSizeList: [
        {
          id: "A4",
          name: "A4",
        },
        {
          id: "A5",
          name: "A5",
        },
      ],
      print_template: null,
    };
  },
  methods: {
    show() {
      this.formData = {
        usage_type_id: "download",
        // email: null,
        // email_resident_id: null,
        file_type: "pdf",
        paper_size: "A5",
        copies: 2,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
      this.$emit("hidden");
    },
    handleUsageTypeChange() {
      if (this.formData.usage_type_id === "email-resident") {
        this.formData.email_resident_id = this.selected[0].resident_id;
      } else {
        this.formData.email_resident_id = null;
      }
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }
      this.isLoading = true;
      const item = this.selected[0];

      return this.$api
        .query(`income/collectings/${item.collecting_id}`)
        .then((response) => {
          // eslint-disable-next-line
          this.receiptData = Object.assign({}, response.data.data);
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.receiptData[index] = response.data.data[index];
            }
          }

          for (const index in this.receiptData.debt_collectings) {
            const item = this.receiptData.debt_collectings[index];

            const totalAmount =
              (item.amount * 100 + item.deferment_amount * 100) / 100;

            this.receiptData.debt_collectings[index].total_amount =
              this.numberToLocaleFormat(totalAmount);
            item.amount = this.numberToLocaleFormat(item.amount);
            item.deferment_amount = this.numberToLocaleFormat(
              item.deferment_amount
            );

            if (!this.receiptData.collecting_number) {
              this.receiptData.collecting_number =
                this.receiptData.balance_activity.balance_no;
            }
          }
        })
        .then(() => {
          const params = Object.assign(
            {},
            {
              house_id: this.receiptData.house_id,
              resident_id: this.receiptData.resident_id,
            }
          );

          return this.$api
            .query(`clusters/${this.clusterId}/house-users`, { params })
            .then((response) => {
              if (response.data.data && response.data.data.length) {
                this.receiptData.balance = this.numberToLocaleFormat(
                  response.data.data[0].balance
                );
              } else {
                throw new Error("House user not found");
              }
            });
        })
        .then(() => {
          const params = {
            file_type: this.formData.file_type,
            file_name: "tahsilat-makbuzu",
          };
          params.print_template = "CollectingDetail";
          params.receiptData = this.receiptData;
          params.receiptData.collected_on = new Date(
            params.receiptData.collected_on
          ).toLocaleDateString();

          params.receiptData.amount = this.numberToLocaleFormat(
            params.receiptData.amount
          );

          if (params.receiptData.balance_activity.house_user_balance) {
            params.receiptData.balance_activity.house_user_balance =
              this.numberToLocaleFormat(
                params.receiptData.balance_activity.house_user_balance
              );
          }

          for (const index in params.receiptData.debt_collectings) {
            const item = params.receiptData.debt_collectings[index];
            item.debt.house_assessment.issues_on = new Date(
              item.debt.house_assessment.issues_on
            ).toLocaleDateString();
            item.debt.house_assessment.last_payment_on = new Date(
              item.debt.house_assessment.last_payment_on
            ).toLocaleDateString();
          }

          if (this.formData.usage_type_id === "email-resident") {
            params.email_resident_id = this.formData.email_resident_id;
          } else if (this.formData.usage_type_id === "email-any") {
            params.email = this.formData.email;
            params.cluster_id = this.clusterId;
          } else if (this.formData.usage_type_id === "download") {
            params.paper_size = this.formData.paper_size;
            params.copies = this.formData.copies;
          }

          params.paper_size = this.formData.paper_size;
          params.copies = this.formData.copies;

          return this.$api.post("print-file", params).then((response) => {
            if (this.formData.usage_type_id === "download") {
              if (response.data.data.url) {
                const a = document.createElement("a");
                a.href = response.data.data.url;
                a.setAttribute("target", "_blank");
                a.click();
                if (event && event.closeOnSave) {
                  this.hide();
                }
              } else {
                this.$toast.error("Tahsilat fişi üretilemedi.");
              }
            } else {
              this.$toast.success("Gönderildi");
            }

            this.hide();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
