<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Avans İade</template>
    <template slot="body">
      <v-form
        ref="form"
        @submit.prevent="handleFormSubmit({ closeOnSave: true })"
      >
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.date')"
              v-model="formData.spent_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-select-collecting
              label="Ödeme Yeri"
              :rules="[rules.required]"
              :bank-account-id.sync="formData.bank_account_id"
              :safe-id.sync="formData.safe_id"
              required
              :readonly="disabled"
              :filled="disabled"
              can-pay
              is-active
            />
          </v-col>
          <v-col sm="6">
            <rs-select
              label="İade Nedeni"
              v-model="formData.refund_type_id"
              :rules="[rules.required]"
              :items="paybackReasonsList.filter((item) => item.is_active)"
              required
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <rs-money-field
              label="İade Tutarı"
              v-model="formData.amount"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, maxAmount),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              rows="2"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-save-close
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";
import { mapGetters } from "vuex";

export default {
  name: "RefundCollectingForm",
  mixins: [hasForm],
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["paybackReasonsList"]),
    maxAmount() {
      if (this.selected.length === 1) {
        return -1 * parseFloat(this.selected[0].total_balance);
      } else if (this.selected.length > 1) {
        let maxAmount = 0;
        for (const index in this.selected) {
          maxAmount += -1 * parseFloat(this.selected[index].total_balance);
        }
        return maxAmount;
      }

      return null;
    },
  },
  data() {
    return {
      onlyHouseUsers: true,
      totalAmount: null,
    };
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();

      this.formData = {
        spent_on: new Date().toJSON().split("T")[0],
        collecting_id: null,
        refund_type_id: null,
        amount: this.maxAmount,
        description: null,
        safe_id: null,
        bank_account_id: null,
      };
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      const collectingId = this.selected[0].collecting_id;

      return this.$api
        .post(`income/collectings/${collectingId}/payback`, formData)
        .then(() => {
          this.$toast.success("Avans iadesi yapıldı.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
