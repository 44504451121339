<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bağımsız Bölüm Serbest Tahsilat</template>
    <template slot="body">
      <v-form
        ref="form"
        @submit.prevent="handleFormSubmit({ closeOnSave: true })"
      >
        <v-row dense>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.date')"
              v-model="formData.collected_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
              @change="generateDescription()"
            />
          </v-col>
          <v-col cols="6">
            <rs-select
              label="Tahsilat Türü"
              :items="collectingTypeList"
              v-model="formData.collecting_type_id"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <rs-select-collecting
              label="Tahsilat Yeri"
              :rules="[rules.required]"
              :bank-account-id.sync="formData.bank_account_id"
              :safe-id.sync="formData.safe_id"
              required
              :readonly="disabled"
              :filled="disabled"
              can-collect
              is-active
            />
          </v-col>
          <v-col cols="6">
            <rs-select-resident
              v-if="house"
              label="Ödeyen"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="cluster.id"
              :house-id="house.id"
              :readonly="disabled || residentDisabled"
              :filled="disabled || residentDisabled"
              required
            />
          </v-col>
          <v-col cols="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, 9999999.99),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <rs-text-field
              label="Tahsilat Makbuz No"
              v-model="formData.collecting_number"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="12">
            <rs-select-project
              v-model="formData.project_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6">
            <v-switch
              label="Tahsilat Mobil Bildirimi Gönder"
              v-model="formData.send_push_notification"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="6">
            <v-switch
              label="Makbuz Yazdır"
              v-model="formData.print_receipt"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="12">
            <rs-textarea
              label="Açıklama"
              counter="255"
              v-model="formData.description"
              :rules="
                disabled
                  ? []
                  : [rules.required, rules.maxLength(formData.description, 255)]
              "
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>

      <CollectingReceiptForm
        :house="house"
        ref="collectingReceiptForm"
        :selected="[
          { collecting_id: id, resident_id: this.formData.resident_id },
        ]"
        :z-index="100"
        @hidden="handleCollectingReceiptFormHidden"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import { CollectingReceiptForm } from "@/view/pages/income/forms";

export default {
  name: "FreeCollectingForm",
  components: { CollectingReceiptForm },
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
    debtList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["cluster", "collectingTypeList", "incomeTypeList"]),
  },
  watch: {
    "formData.resident_id"() {
      if (
        this.formData.resident_id &&
        this.formData.resident_id.constructor.name === "Object"
      ) {
        this.formData.resident_id = this.formData.resident_id.id;
      }
      this.calculateAmount();
    },
    "formData.collecting_type_id"() {
      this.calculateAmount();
      this.generateDescription();
    },
  },
  data() {
    return {
      isLoading: false,
      formData: {},
      id: null,
      residentDisabled: false,
    };
  },
  methods: {
    show(residentId) {
      this.formData = {
        resident_id: null,
        safe_id: null,
        bank_account_id: null,
        collected_on: new Date().toISOString().substr(0, 10),
        amount: null,
        collecting_number: null,
        project_id: null,
        send_push_notification: true,
        print_receipt: false,
        description: null,
        collecting_type_id: 1,
      };
      this.residentDisabled = false;

      if (residentId) {
        this.formData.resident_id = residentId;
        this.residentDisabled = true;
      } else if (this.house.house_users.length === 1) {
        this.formData.resident_id = this.house.house_users[0];
      }

      this.calculateAmount();
      this.generateDescription();

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign(
        {
          house_id: this.house.id,
        },
        this.formData
      );

      if (this.id) {
        this.$api
          .put(`income/collectings/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", this.formData);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("income/collectings", formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", this.formData));

            if (this.formData.print_receipt) {
              this.id = response.data.data.id;
              this.$refs.collectingReceiptForm.show();
            } else {
              if (event && event.closeOnSave) {
                this.hide();
                return;
              }
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    calculateAmount() {
      const residentId = this.formData.resident_id || null;
      const collectingTypeId = this.formData.collecting_type_id || null;

      if (!residentId) {
        // eslint-disable-next-line
        console.log("residentId does not exist");
        this.formData.amount = 0;
        return;
      }

      let amount = 0;
      for (const index in this.debtList) {
        const debt = this.debtList[index];

        if (!debt.debt_id) {
          // eslint-disable-next-line
          // console.log("no debt_id", debt);
          continue;
        }

        if (debt.has_enforcement) {
          // eslint-disable-next-line
          // console.log("no debt_id", debt);
          continue;
        }

        if (debt.resident_id != residentId) {
          // eslint-disable-next-line
          // console.log("resident ids don't match", debt.resident_id, residentId);
          continue;
        }

        if (
          collectingTypeId !== 1 &&
          !this.incomeMatchesCollecting(debt.income_type_id, collectingTypeId)
        ) {
          // eslint-disable-next-line
          // console.log("income type and collecting type don't match", debt);
          continue;
        }

        // console.log(debt);

        amount += parseFloat(debt.total_balance) * 100;
      }

      this.formData.amount = Math.round(amount) / 100;
    },
    incomeMatchesCollecting(incomeTypeId, collectingTypeId) {
      let collectingType, incomeType;

      for (const index in this.collectingTypeList) {
        if (parseInt(collectingTypeId) === this.collectingTypeList[index].id) {
          collectingType = this.collectingTypeList[index];

          if (!collectingType.income_type_id) {
            return false;
          }

          break;
        }
      }

      for (const index in this.incomeTypeList) {
        if (parseInt(incomeTypeId) === this.incomeTypeList[index].id) {
          incomeType = this.incomeTypeList[index];
          break;
        }
      }

      if (incomeType === undefined) {
        // eslint-disable-next-line
        console.error(
          "Income type not found",
          incomeTypeId,
          this.incomeTypeList
        );
        return false;
      }

      if (incomeType.id === collectingType.income_type_id) {
        return true;
      }

      return false;
    },
    generateDescription() {
      let description = "";

      if (this.formData.collecting_type_id) {
        for (const index in this.collectingTypeList) {
          if (
            this.collectingTypeList[index].id ===
            this.formData.collecting_type_id
          ) {
            description = "[" + this.collectingTypeList[index].name + " - ";
            description +=
              new Date(this.formData.collected_on).toLocaleDateString() + "] ";
            break;
          }
        }
      }

      this.formData.description = description;
    },
    handleCollectingReceiptFormHidden() {
      this.hide();
    },
  },
};
</script>
