<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Gecikme Uygula</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.last_payment_date')"
              v-model="formData.last_payment_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
            />
          </v-col>

          <v-col sm="6">
            <rs-datepicker
              label="Geçerlilik Tarihi"
              v-model="formData.deferment_date"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="6">
            <rs-select
              label="Gecikme Hesaplama Türü"
              :items="[
                { id: 'daily', name: 'Günlük' },
                { id: 'monthly', name: 'Aylık' },
              ]"
              :value="
                selected[0] ? selected[0].deferment_calculation_period : null
              "
              readonly
              filled
              required
            />
          </v-col>

          <v-col sm="6">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              max="100"
              :label="$t('labels.delay_percentage')"
              v-model="formData.deferment_interest_percent"
              :readonly="disabled"
              :filled="disabled"
              prefix="%"
              :rules="[
                rules.required,
                rules.max(formData.deferment_interest_percent, 100),
              ]"
              required
            />
          </v-col>

          <v-col sm="6">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              :label="$t('labels.delay_option_days')"
              v-model="formData.deferment_grace_period"
              :readonly="disabled"
              :filled="disabled"
              suffix="Gün"
            />
          </v-col>

          <v-col sm="6">
            <v-switch
              v-model="stopDeferment"
              label="Geçerlilik Tarihinden Sonra Gecikmeyi Durdur"
              hide-details="auto"
              :readonly="disabled || !formData.deferment_date"
              :filled="disabled || !formData.deferment_date"
            ></v-switch>
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <DebtList :list="selected" />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "ApplyDeferForm",
  mixins: [hasForm],
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["delayCalculationTypeList"]),
  },
  watch: {
    stopDeferment(newValue) {
      if (newValue) {
        this.formData.stop_deferment_on = this.formData.deferment_date;
      } else {
        this.formData.stop_deferment_on = null;
      }
    },
  },
  data() {
    return {
      formData: {},
      stopDeferment: false,
    };
  },
  methods: {
    show() {
      this.formData = {
        deferment_interest_percent: null,
        deferment_grace_period: null,
        deferment_date: new Date().toJSON().split("T")[0],
        stop_deferment_on: null,
        last_payment_on: null,
      };
      this.stopDeferment = false;

      this.$refs.sidePanel.show();

      if (this.selected.length === 1) {
        this.load();
      } else {
        let date = this.selected[0].last_payment_on.toJSON().split("T")[0];

        for (const index in this.selected) {
          const itemDate = this.selected[index].last_payment_on
            .toJSON()
            .split("T")[0];

          if (itemDate !== date) {
            date = null;
            break;
          }
        }

        if (date !== null) {
          this.formData.last_payment_on = date;
        }
      }
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading) return false;

      this.isLoading = true;

      this.$api
        .query(`income/debts/${this.selected[0].debt_id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (this.formData.deferment_date) {
            this.formData.deferment_date =
              this.formData.deferment_date.split("T")[0];
          }

          if (this.formData.stop_deferment_on) {
            this.stopDeferment = true;

            if (
              this.formData.stop_deferment_on < this.formData.deferment_date
            ) {
              this.formData.deferment_date =
                this.formData.stop_deferment_on.split("T")[0];
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.debts = [];
      this.selected.forEach((debt) => {
        formData.debts.push(debt.debt_id);
      });

      this.formData.stop_deferment_on = this.stopDeferment
        ? this.formData.deferment_date
        : null;

      this.$api
        .put("income/debts", formData)
        .then(() => {
          this.$toast.success("Gecikme uygulandı.");
          this.$emit("saved", this.formData);
          if (event && event.closeOnSave) {
            this.hide();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
